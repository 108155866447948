<template>
  <!DOCTYPE html>
  <html>
  <head>
  <title>Terms and Conditions</title>
  <meta http-equiv="content-type" content="text/html; charset=utf-8" >
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css">
  </head>
  <body>
  <div class="section">
    <br>
    <br>
    <h2 class="title">AlertTek Alert Terms and Conditions for Policy Short Code</h2>
    <br>
    <br>
  <p><strong><span style="font-size:12pt;">Program Description:&nbsp;</span></strong><span style="font-size:12pt;">AlertTek (&ldquo;Organization&rdquo;) is offering its Mobile Alert Program, a text message alert program (the &ldquo;Program&rdquo;), subject to these Mobile Terms and Conditions (the &ldquo;Terms&rdquo;). &nbsp;The Program allows participants to receive, without limitation, informational alerts of interest to them; polling questions; information regarding the economy, civic participation, and politics; and general advocacy of interest to Program members. &nbsp;By providing your personal information when presented with a link to, or other notice of the existence of, these Terms, you agree that you are bound by these Terms and agree to receive communications from Company. &nbsp;If you do not wish to continue participating in the Program or no longer agree to these Terms, you can reply &ldquo;</span><strong><span style="font-size:12pt;">STOP</span></strong><span style="font-size:12pt;">&rdquo; to any mobile message from Company in order to opt out of the Program. &nbsp;</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">User Opt In:&nbsp;</span></strong><span style="font-size:12pt;">The Program allows users to receive SMS/MMS (&ldquo;mobile&rdquo;) alerts that include alerts, information, offers, and rewards by users affirmatively opting into the Program, such as by signing up online, by providing your telephone number, or by texting a keyword to Organization in response to a written or verbal call-to-action. Regardless of the opt-in method you utilized to join the Program, you agree that these Terms apply to your participation in the Program. The mobile messaging service used by Company to communicate with you does not have the capacity to generate telephone numbers or initiate messages without human intervention. &nbsp;Thus, Company&rsquo;s mobile messages are not sent to you by an automatic telephone dialing system (&ldquo;ATDS&rdquo; or &ldquo;autodialer&rdquo;). &nbsp;Nevertheless, by opting into the Program,&nbsp;</span><strong><span style="font-size:12pt;">you agree to receive autodialed mobile messages and you understand that consent is not required to make any purchase from Company.</span></strong><span style="font-size:12pt;">&nbsp;&nbsp;</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">Cost and Frequency:</span></strong><span style="font-size:12pt;">&nbsp;Message and data rates may apply. &nbsp;The Program involves recurring mobile messages, and additional mobile messages may be sent based on your interaction with Organization.</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">Contact Information:&nbsp;</span></strong><span style="font-size:12pt;">For support, text&nbsp;</span><strong><span style="font-size:12pt;">&ldquo;HELP&rdquo;</span></strong><span style="font-size:12pt;">&nbsp;to any Organization mobile message.</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">User Opt Out and Additional Commands:&nbsp;</span></strong><span style="font-size:12pt;">To opt out of receiving mobile messages, reply&nbsp;</span><strong><span style="font-size:12pt;">&ldquo;STOP&rdquo;&nbsp;</span></strong><span style="font-size:12pt;">to any mobile message you received from Organization on your mobile device. &nbsp;This is the easiest and preferred method to opt out receiving mobile messages through the Program. You may receive an additional mobile message confirming your decision to opt out. The Program may recognize or respond to additional commands and keyword queries. &nbsp;Thus, you may receive additional informational text messages based on your interaction with the Program, even after opting out of receiving the Program&rsquo;s recurring alerts. &nbsp;You acknowledge and agree that, notwithstanding any prior opt-out attempt, you consent to receive further messages from or on behalf of Company that result from &nbsp;your continued communication with the Program. Company may also provide you instructions on how to rejoin receiving the Program&rsquo;s recurring mobile alerts when you unsubscribe. &nbsp;You agree that you are subject to the Terms, including any modifications thereto then in effect, when you resubscribe to the Program through any of the available options to do so. &nbsp;</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">MMS:&nbsp;</span></strong><span style="font-size:12pt;">The Program will send SMS MTs if your mobile device does not support MMS messaging.</span><span style="font-size:12pt;"><br><br></span></p>
  <p><strong><span style="font-size:12pt;">Organization Warranty:&nbsp;</span></strong><span style="font-size:12pt;">Company will not be liable for any delays or failures in the receipt of any messages connected with this Program. Delivery of messages is subject to effective transmission from your wireless service provider/network operator, and is outside of Company&rsquo;s control.&nbsp;</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">Privacy Policy:&nbsp;</span></strong><span style="font-size:12pt;">Organization will only use information you provide to operate the Program and respond to you, if necessary.&nbsp;</span><strong><span style="font-size:12pt;">COMPANY DOES NOT SELL, RENT, LOAN, TRADE, LEASE OR OTHERWISE TRANSFER FOR PROFIT ANY PHONE NUMBERS OR PERSONAL INFORMATION COLLECTED THROUGH THE PROGRAM TO ANY THIRD PARTY.</span></strong><span style="font-size:12pt;">&nbsp;Nonetheless, Company reserves the right at all times to disclose any information as necessary to satisfy any law, regulation or governmental request, to avoid liability, or to protect Company&rsquo;s rights or property, and as needed to operate the Program. When you complete forms online or otherwise provide Company information in connection with the Program, you agree to provide accurate, complete, and true information. You agree not to use a false or misleading name or a name that you are not authorized to use. If Company, in its sole discretion, believes that any such information is untrue, inaccurate, or incomplete, or you have opted into the Program for an ulterior purpose, Company may refuse you access to the Program and pursue any appropriate legal remedies.</span></p>
  <p><br></p>
  <p><span style="font-size:12pt;">This Privacy Policy is strictly limited to the Program and has no effect on any other privacy policy(ies) that may govern the relationship between you and Organization in other contexts. &nbsp;&nbsp;</span></p>
  <p><br></p>
  <p><strong><span style="font-size:12pt;">Supported Carriers:</span></strong><span style="font-size:12pt;">&nbsp;Carriers are not liable for delayed or undelivered messages. The Program is available on the following carriers:</span></p>
  <p><br></p>
  <ul>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">AT&amp;T</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Boost Mobile</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Cricket/Leap</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Metro PCS</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">T-Mobile</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">US Cellular Corp</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Verizon Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Virgin Mobile USA</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">AIO Jasper</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">ACS Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">All West Communications</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Appalachian Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Atlantic Tele/Choice</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">AWCC</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Bluegrass Cellular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Brightlink</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Carolina West Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Cellcom</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Cellular One of East Central / ECIT (East Central Illinois)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Cellular South d.b.a. C Spire</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">CellularOne (of North East Arizona)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Chariton Valley Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Chat Mobility</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Copper Valley</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">CTC Telecom</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Custer Telephone</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">DTC Wireless (Advantage Cellular Systems)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Duet IP</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Eagle Telephone System</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Element Wireless ( See Notes Column)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Enflick Inc</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Epic Touch</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Farmers Mutual Telephone</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Flat Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">GCI / Alaska Digitel</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Gold Star Communications</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Google Voice</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Illinois Valley</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Immix Wireless/Keystone Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Inland Cellular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Iowa Wireless (iWireless)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Layered Communications</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Leaco Rural Telephone Coope</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Manti Tel</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Mid-Rivers Communications ( aka Cable &amp; Cellular Communications, LLC )</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Mobi PCS</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">MobileNation/SI Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Mosaic</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">MTA</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">MTPCS (Cellular One, CellOne Nation)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">NE Cellular One of PA</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Nemont Sagebrush Cellular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Nex-Tech</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">NTELOS</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Nucla-Naturita Tel</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">NW Missouri Celllular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Peoples Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Pine Cellular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Pioneer Cellular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Plateau Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">PTCI (Panhandle Wireless)</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Republic Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">South Central Utah</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">SouthernLINC Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">SRT Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Standing Rock</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Syringa Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Thumb Cellular</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Triangle Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">UBET</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Union Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">United Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">Viaero Wireless</span></p>
      </li>
      <li style="list-style-type:disc;font-size:10pt;">
          <p><span style="font-size:12pt;">West Central Cellular</span></p>
      </li>
  </ul>
  <p><br></p>
  <p><span style="font-size:12pt;">MMS Carriers</span></p>
  <p><br></p>
  <div align="left">
      <table style="border:none;border-collapse:collapse;">
          <tbody>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">AT&amp;T</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">Boost Mobile</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">Cricket/Leap</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">Metro PCS</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">T-Mobile</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">US Cellular Corp</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">Verizon Wireless</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
              <tr>
                  <td>
                      <ul>
                          <li style="list-style-type:disc;font-size:10pt;">
                              <p><span style="font-size:12pt;">Virgin Mobile USA</span></p>
                          </li>
                      </ul>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
  <p><strong><span style="font-size:12pt;">Miscellaneous:&nbsp;</span></strong><span style="font-size:12pt;">You warrant and represent to Organization that you have all necessary rights, power, and authority to agree to these Terms and perform your obligations hereunder, and nothing contained in this Agreement or in the performance of such obligations will place you in breach of any other contract or obligation. &nbsp;The failure of either party to exercise in any respect any right provided for herein will not be deemed a waiver of any further rights hereunder. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable. Any new features, changes, updates or improvements of the Program shall be subject to these Terms unless explicitly stated otherwise in writing. Company reserves the right to change these Terms from time to time. Any updates to these Terms shall be communicated to you. You acknowledge your responsibility to review these Terms from time to time and to be aware of any such changes. By continuing to participate in the Program after any such changes, you accept these Terms, as modified. You further warrant and represent that you will not provide to Company any telephone number that is not assigned to you, and that if you obtain a new telephone number, you will promptly notify Company that your prior telephone number is no longer assigned to you.</span></p>
  <p><br></p>
  <p><br></p>
  </div>
  </body>
  </html>
</template>
